import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { MessagesService } from 'app/layout/common/messages/messages.service';
import { NavigationService } from 'app/core/navigation/navigation.service';
import { NotificationsService } from 'app/layout/common/notifications/notifications.service';
import { QuickChatService } from 'app/layout/common/quick-chat/quick-chat.service';
import { ShortcutsService } from 'app/layout/common/shortcuts/shortcuts.service';
import { UserService } from 'app/core/user/user.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './core/auth/auth.service';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(
        private _userService: UserService, private authService: AuthService, private cookieService: CookieService
    ) {
        // this.cookieService.set('marketing_token', 'e5db0f70d88a527c6061f584cf64aad05780dbba384d8c4cd7b704d38413fa4d919e6bd7')

        if (this.cookieService.get('marketing_token')?.length > 0) {
            this.authService.accessToken = this.cookieService.get('marketing_token')
            // this.cookieService.delete('marketing_token', '/',)
            let DOMAIN = environment.production ? 'printingwarehouses.com' : 'printingw.xyz'
            this.cookieService.set('marketing_token', '', { domain: DOMAIN, sameSite: 'None', expires: new Date(2020, 1, 1), path: '', secure: true })
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Use this resolver to resolve initial mock-api for the application
     *
     * @param route
     * @param state
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        // Fork join multiple API endpoint calls to wait all of them to finish
        return forkJoin([
            this._userService.get()
        ]);
    }
}
