import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InitialData } from './ad.types';
import { ContactService } from 'app/core/contact/contact.service';
import { FacebookService } from 'app/core/digital-marketing/facebook.service';

@Injectable({
    providedIn: 'root'
})
export class FBAdDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _fbService: FacebookService, private router: Router, private user: ContactService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        let isAdmin = localStorage.getItem('account_type')?.includes('admin')
        let ads = this._fbService.getFBAdsInitial().pipe(
            map((ads) => ({
                ads
            })
            ),
            catchError(err => {
                this._fbService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                return of(new DefaultResponse().ads);
            })
        )

        let users = this.user.getContacts('user', null, 100, null, null, null, null)
        let campaigns = this._fbService.getFBCampaigns(null, null, null, null)

        let sources = [campaigns, ads]
        if (isAdmin)
            sources.push(users)
            
        return forkJoin(sources)
    }

}

class DefaultResponse implements InitialData {
    ads: any = [];
}