import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, tap, finalize } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { Survey, SurveyResponse } from './survey.model';
import { Pagination } from 'app/shared/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class SurveyService {

    private _surveys: BehaviorSubject<Survey[] | null> = new BehaviorSubject(null);
    pagination: Pagination;
    errorMessage: string = "Something went wrong ";

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for surveys
     */
    get surveys$(): Observable<Survey[]> {
        return this._surveys.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get survey list
     */
    getSurveysInitial(): Observable<SurveyResponse> {
        return this.getSurveys(null, null, null, null, null);
    }

    /**
     * Get survey list with search and sort
     */
    getSurveys(page: number = 0, batch_size: number = 10, sort: string = 'added_at', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string): Observable<SurveyResponse> {
        if (sortDirection == 'desc')
            sort = '-' + sort;
        return this._httpClient.get<SurveyResponse>(`${BASE_URL}digital_services/ad_content_survey/`, {
            params: {
                page: page ? ++page : 1,
                order_by: sort ? sort : '',
                search: query ? query : '',
                batch_size: batch_size ? batch_size : 10
            }
        }).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this.pagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._surveys.next(response.data ? response.data : []);
            })
        );
    }

    /**
     * Activate or suspend an survey
     * @param surveyData 'true' to activate 'false' to suspend
     * @param surveyId
     */
    updateSurvey(surveyData, surveyId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}digital_services/ad_content_survey/${surveyId}/`, surveyData);
    }

    /**
    * Create survey
    *
    * @param surveyData
    */
    createSurvey(surveyData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_services/ad_content_survey/`, surveyData);
    }

    /**
    * Delete survey
    *
    * @param surveyId
    */
    deleteSurvey(surveyId): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}digital_services/ad_content_survey/${surveyId}/`).pipe(
            tap((response) => {
                this._surveys.next(this._surveys.value.filter(survey => survey.id !== surveyId));
            })
        )
    }



}
