import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DigitalMarketingService } from 'app/core/digital-marketing/digital-marketing.service';
import { InitialData } from './dm-ad-device.types';
import { ContactService } from 'app/core/contact/contact.service';

@Injectable({
    providedIn: 'root'
})
export class DMAdDeviceDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _dmCampaignService: DigitalMarketingService, private router: Router, private user: ContactService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

        let users = this.user.getContacts('user', null, null, null, null, null, null)
        let devices = this._dmCampaignService.getDMAdDevicesInitial(route?.queryParams['campaign']).pipe(
            map((dmAdDevices) => ({
                dmAdDevices
            })
            ),
            catchError(err => {
                this._dmCampaignService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                return of(new DefaultResponse().dmAdDevices);
            })
        )
        let campaigns = this._dmCampaignService.getDMCampaignsInitial()
        let sources = [devices]

        if (localStorage.getItem('account_type')?.includes('admin')) {
            sources.push(users)
            sources.push(campaigns)
        }
        return forkJoin(sources)
    }

}

class DefaultResponse implements InitialData {
    dmAdDevices: any = [];
}