import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, tap, finalize } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { FacebookCampaignResponse, FacebookCampaign, FacebookAd, FacebookAdsResponse, FacebookCampaignPerformance, FacebookCampaignPerformanceResponse, FacebookDemographicReportResponse, FacebookDemographicReport, FacebookPlatformReport, FacebookPlatformReportResponse, FacebookDeviceReportResponse, FacebookDeviceReport, FacebookLocationReportResponse, FacebookLocationReport } from './facebook.model';
import { Pagination } from 'app/shared/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class FacebookService {

    errorMessage: string = "Something went wrong ";

    constructor(private _httpClient: HttpClient) {
    }

    // Campaigns - Start
    private _fbCampaigns: BehaviorSubject<FacebookCampaign[] | null> = new BehaviorSubject(null);
    private _fbCampaign: BehaviorSubject<FacebookCampaign | null> = new BehaviorSubject(null);
    campaignPagination: Pagination;

    get fbCampaigns$(): Observable<FacebookCampaign[]> {
        return this._fbCampaigns.asObservable();
    }
    get fbCampaign$(): Observable<FacebookCampaign> {
        return this._fbCampaign.asObservable();
    }
    getFBCampaignsInitial(search: string = null): Observable<FacebookCampaignResponse> {
        return this.getFBCampaigns(null, null, search, null);
    }
    getFBCampaigns(pagination, sort, query: string, user, processResponse: boolean = true): Observable<FacebookCampaignResponse> {
        let sortDirection = sort?.direction ?? 'asc'
        let page = pagination?.pageIndex
        let batch_size = pagination?.pageSize

        if (sortDirection == 'desc')
            sort = '-' + sort;
        let params = {
            page: page ? ++page : 1,
            ordering: sort?.active ?? '',
            search: query ? query : '',
            batch_size: batch_size ? batch_size : 10
        }
        if (user != null && user > 0)
            params['user'] = user
        return this._httpClient.get<FacebookCampaignResponse>(`${BASE_URL}digital_marketing/facebook-campaigns/`, {
            params: params
        }).pipe(
            tap((response) => {
                if (!processResponse)
                    return
                this.errorMessage = response.message;
                this.campaignPagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._fbCampaigns.next(response.data ? response.data : []);
            })
        );
    }
    updateFBCampaign(campaignData, campaignId): Observable<any> {
        return this._httpClient.put(`${BASE_URL}digital_marketing/facebook-campaigns/${campaignId}/`, campaignData);
    }
    createFBCampaign(campaignData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/facebook-campaigns/`, campaignData);
    }
    deleteFBCampaign(campaignId): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}digital_marketing/facebook-campaigns/${campaignId}/`).pipe(
            tap((response) => {
                this._fbCampaigns.next(this._fbCampaigns.value.filter(campaign => campaign.id !== campaignId));
            })
        )
    }

    updateBudgetRequest(campaignData, campaignId): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/facebook-campaign-update-request/`, campaignData);
    }
    // Campaigns - End


    // Ads - Start
    private _fbAds: BehaviorSubject<FacebookAd[] | null> = new BehaviorSubject(null);
    private _fbAd: BehaviorSubject<FacebookAd | null> = new BehaviorSubject(null);
    adPagination: Pagination;

    get fbAds$(): Observable<FacebookAd[]> {
        return this._fbAds.asObservable();
    }
    get fbAd$(): Observable<FacebookAd> {
        return this._fbAd.asObservable();
    }
    getFBAdsInitial(search: string = null): Observable<FacebookAdsResponse> {
        return this.getFBAds(null, null, search, null, null);
    }
    getFBAds(pagination, sort, query: string, user, campaign, processResponse: boolean = true, adType: string = null): Observable<FacebookAdsResponse> {
        let sortDirection = sort?.direction ?? 'asc'
        let page = pagination?.pageIndex
        let batch_size = pagination?.pageSize

        if (sortDirection == 'desc')
            sort = '-' + sort;
        let params = {
            page: page ? ++page : 1,
            ordering: sort?.active ?? '',
            search: query ? query : '',
            batch_size: batch_size ? batch_size : 10
        }
        if (user != null && user > 0)
            params['user'] = user
        if (campaign != null && campaign > 0)
            params['campaign'] = campaign
        if (adType != null && adType != "0")
            params['type'] = adType
        return this._httpClient.get<FacebookAdsResponse>(`${BASE_URL}digital_marketing/facebook-ads/`, {
            params: params
        }).pipe(
            tap((response) => {
                if (!processResponse)
                    return
                this.errorMessage = response.message;
                this.adPagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._fbAds.next(response.data ? response.data : []);
            })
        );
    }
    updateFBAd(adData, adId): Observable<any> {
        return this._httpClient.put(`${BASE_URL}digital_marketing/facebook-ads/${adId}/`, adData);
    }
    createFBAd(adData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/facebook-ads/`, adData);
    }
    deleteFBAd(adId): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}digital_marketing/facebook-ads/${adId}/`).pipe(
            tap((response) => {
                this._fbAds.next(this._fbAds.value.filter(ad => ad.id !== adId));
            })
        )
    }
    // Ads - End

    // Campaign Performance - Start
    private _campaignPerformances: BehaviorSubject<FacebookCampaignPerformance[] | null> = new BehaviorSubject(null);
    private _campaignPerformance: BehaviorSubject<FacebookCampaignPerformance | null> = new BehaviorSubject(null);
    campaignPerformancePagination: Pagination;

    get campaignPerformances$(): Observable<FacebookCampaignPerformance[]> {
        return this._campaignPerformances.asObservable();
    }
    get campaignPerformance$(): Observable<FacebookCampaignPerformance> {
        return this._campaignPerformance.asObservable();
    }
    getCampaignPerformancesInitial(search: string = null): Observable<FacebookCampaignPerformanceResponse> {
        return this.getCampaignPerformances(null, null, search, null, null, null, true);
    }
    getCampaignPerformances(pagination, sort, query: string, user, startDate, endDate, processResponse: boolean = true): Observable<FacebookCampaignPerformanceResponse> {
        let sortDirection = sort?.direction ?? 'asc'
        let page = pagination?.pageIndex
        let batch_size = pagination?.pageSize
        let sortKey = sort?.active

        if (sortKey != null && sortDirection == 'desc')
            sortKey = '-' + sortKey;
        let params = {
            page: page ? ++page : 1,
            ordering: sortKey ?? '',
            search: query ? query : '',
            batch_size: batch_size ? batch_size : 10
        }
        if (user != null && user > 0)
            params['user'] = user
        console.log(startDate);

        if (startDate != null)
            params['start_date'] = startDate
        if (endDate != null)
            params['end_date'] = endDate

        return this._httpClient.get<FacebookCampaignPerformanceResponse>(`${BASE_URL}digital_marketing/facebook-campaign-performance-reports/`, {
            params: params
        }).pipe(
            tap((response) => {
                if (!processResponse)
                    return
                this.errorMessage = response.message;
                this.campaignPerformancePagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._campaignPerformances.next(response.data ? response.data : []);
            })
        );
    }
    updateCampaignPerformance(performanceData, performanceId): Observable<any> {
        return this._httpClient.put(`${BASE_URL}digital_marketing/facebook-campaign-performance-reports/${performanceId}/`, performanceData);
    }
    createCampaignPerformance(performanceData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/facebook-campaign-performance-reports/`, performanceData);
    }
    deleteCampaignPerformance(performanceId): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}digital_marketing/facebook-campaign-performance-reports/${performanceId}/`).pipe(
            tap((response) => {
                this._campaignPerformances.next(this._campaignPerformances.value.filter(ad => ad.id !== performanceId));
            })
        )
    }
    createBulkCampaignPerformance(performanceData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/facebook-performance-reports-update/`, performanceData);
    }
    // Campaign Performance - End

    // Demographic report - Start
    private _demographicReports: BehaviorSubject<FacebookDemographicReport[] | null> = new BehaviorSubject(null);
    private _demographicReport: BehaviorSubject<FacebookDemographicReport | null> = new BehaviorSubject(null);
    demographicReportPagination: Pagination;

    get demographicReports$(): Observable<FacebookDemographicReport[]> {
        return this._demographicReports.asObservable();
    }
    get demographicReport$(): Observable<FacebookDemographicReport> {
        return this._demographicReport.asObservable();
    }
    getDemographicReportsInitial(search: string = null): Observable<FacebookDemographicReportResponse> {
        return this.getDemographicReports(null, null, search, null, null, null, null, true);
    }
    getDemographicReports(pagination, sort, query: string, user, ad, startDate, endDate, processResponse: boolean = true): Observable<FacebookDemographicReportResponse> {
        let sortDirection = sort?.direction ?? 'asc'
        let page = pagination?.pageIndex
        let batch_size = pagination?.pageSize
        let sortKey = sort?.active

        if (sortKey != null && sortDirection == 'desc')
            sortKey = '-' + sortKey;
        let params = {
            page: page ? ++page : 1,
            ordering: sortKey ?? '',
            search: query ? query : '',
            batch_size: batch_size ? batch_size : 10
        }
        if (user != null && user > 0)
            params['user'] = user
        if (startDate != null)
            params['start_date'] = startDate
        if (endDate != null)
            params['end_date'] = endDate
        if (ad != null && ad > 0)
            params['ad'] = ad

        return this._httpClient.get<FacebookDemographicReportResponse>(`${BASE_URL}digital_marketing/facebook-demographic-reports/`, {
            params: params
        }).pipe(
            tap((response) => {
                if (!processResponse)
                    return
                this.errorMessage = response.message;
                this.demographicReportPagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._demographicReports.next(response.data ? response.data : []);
            })
        );
    }
    updateDemographicReport(performanceData, itemId): Observable<any> {
        return this._httpClient.put(`${BASE_URL}digital_marketing/facebook-demographic-reports/${itemId}/`, performanceData);
    }
    createDemographicReport(performanceData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/facebook-demographic-reports/`, performanceData);
    }
    deleteDemographicReport(itemId): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}digital_marketing/facebook-demographic-reports/${itemId}/`).pipe(
            tap((response) => {
                this._demographicReports.next(this._demographicReports.value.filter(ad => ad.id !== itemId));
            })
        )
    }
    createBulkDemographicReport(performanceData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/facebook-demographic-reports-update/`, performanceData);
    }
    // Demographic report - End

    // Platform report - Start
    private _platformReports: BehaviorSubject<FacebookPlatformReport[] | null> = new BehaviorSubject(null);
    private _platformReport: BehaviorSubject<FacebookPlatformReport | null> = new BehaviorSubject(null);
    platformReportPagination: Pagination;

    get platformReports$(): Observable<FacebookPlatformReport[]> {
        return this._platformReports.asObservable();
    }
    get platformReport$(): Observable<FacebookPlatformReport> {
        return this._platformReport.asObservable();
    }
    getPlatformReportsInitial(search: string = null): Observable<FacebookPlatformReportResponse> {
        return this.getPlatformReports(null, null, search, null, null, null, null, true);
    }
    getPlatformReports(pagination, sort, query: string, user, ad, startDate, endDate, processResponse: boolean = true): Observable<FacebookPlatformReportResponse> {
        let sortDirection = sort?.direction ?? 'asc'
        let page = pagination?.pageIndex
        let batch_size = pagination?.pageSize
        let sortKey = sort?.active

        if (sortKey != null && sortDirection == 'desc')
            sortKey = '-' + sortKey;
        let params = {
            page: page ? ++page : 1,
            ordering: sortKey ?? '',
            search: query ? query : '',
            batch_size: batch_size ? batch_size : 10
        }
        if (user != null && user > 0)
            params['user'] = user
        if (startDate != null)
            params['start_date'] = startDate
        if (endDate != null)
            params['end_date'] = endDate
        if (ad != null && ad > 0)
            params['ad'] = ad

        return this._httpClient.get<FacebookPlatformReportResponse>(`${BASE_URL}digital_marketing/facebook-platform-reports/`, {
            params: params
        }).pipe(
            tap((response) => {
                if (!processResponse)
                    return
                this.errorMessage = response.message;
                this.platformReportPagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._platformReports.next(response.data ? response.data : []);
            })
        );
    }
    updatePlatformReport(performanceData, itemId): Observable<any> {
        return this._httpClient.put(`${BASE_URL}digital_marketing/facebook-platform-reports/${itemId}/`, performanceData);
    }
    createPlatformReport(performanceData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/facebook-platform-reports/`, performanceData);
    }
    deletePlatformReport(itemId): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}digital_marketing/facebook-platform-reports/${itemId}/`).pipe(
            tap((response) => {
                this._platformReports.next(this._platformReports.value.filter(ad => ad.id !== itemId));
            })
        )
    }
    createBulkPlatformReport(performanceData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/facebook-platform-reports-update/`, performanceData);
    }
    // Platform report - End

    // Device report - Start
    private _deviceReports: BehaviorSubject<FacebookDeviceReport[] | null> = new BehaviorSubject(null);
    private _deviceReport: BehaviorSubject<FacebookDeviceReport | null> = new BehaviorSubject(null);
    deviceReportPagination: Pagination;

    get deviceReports$(): Observable<FacebookDeviceReport[]> {
        return this._deviceReports.asObservable();
    }
    get deviceReport$(): Observable<FacebookDeviceReport> {
        return this._deviceReport.asObservable();
    }
    getDeviceReportsInitial(search: string = null): Observable<FacebookDeviceReportResponse> {
        return this.getDeviceReports(null, null, search, null, null, null, null, true);
    }
    getDeviceReports(pagination, sort, query: string, user, ad, startDate, endDate, processResponse: boolean = true): Observable<FacebookDeviceReportResponse> {
        let sortDirection = sort?.direction ?? 'asc'
        let page = pagination?.pageIndex
        let batch_size = pagination?.pageSize
        let sortKey = sort?.active

        if (sortKey != null && sortDirection == 'desc')
            sortKey = '-' + sortKey;
        let params = {
            page: page ? ++page : 1,
            ordering: sortKey ?? '',
            search: query ? query : '',
            batch_size: batch_size ? batch_size : 10
        }
        if (user != null && user > 0)
            params['user'] = user
        if (startDate != null)
            params['start_date'] = startDate
        if (endDate != null)
            params['end_date'] = endDate
        if (ad != null && ad > 0)
            params['ad'] = ad

        return this._httpClient.get<FacebookDeviceReportResponse>(`${BASE_URL}digital_marketing/facebook-devices-reports/`, {
            params: params
        }).pipe(
            tap((response) => {
                if (!processResponse)
                    return
                this.errorMessage = response.message;
                this.deviceReportPagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._deviceReports.next(response.data ? response.data : []);
            })
        );
    }
    updateDeviceReport(performanceData, itemId): Observable<any> {
        return this._httpClient.put(`${BASE_URL}digital_marketing/facebook-devices-reports/${itemId}/`, performanceData);
    }
    createDeviceReport(performanceData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/facebook-devices-reports/`, performanceData);
    }
    deleteDeviceReport(itemId): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}digital_marketing/facebook-devices-reports/${itemId}/`).pipe(
            tap((response) => {
                this._deviceReports.next(this._deviceReports.value.filter(ad => ad.id !== itemId));
            })
        )
    }
    createBulkDeviceReport(performanceData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/facebook-device-reports-update/`, performanceData);
    }
    // Device report - End

    // Location report - Start
    private _locationReports: BehaviorSubject<FacebookLocationReport[] | null> = new BehaviorSubject(null);
    private _locationReport: BehaviorSubject<FacebookLocationReport | null> = new BehaviorSubject(null);
    locationReportPagination: Pagination;

    get locationReports$(): Observable<FacebookLocationReport[]> {
        return this._locationReports.asObservable();
    }
    get locationReport$(): Observable<FacebookLocationReport> {
        return this._locationReport.asObservable();
    }
    getLocationReportsInitial(search: string = null): Observable<FacebookLocationReportResponse> {
        return this.getLocationReports(null, null, search, null, null, null, null, true);
    }
    getLocationReports(pagination, sort, query: string, user, ad, startDate, endDate, processResponse: boolean = true): Observable<FacebookLocationReportResponse> {
        let sortDirection = sort?.direction ?? 'asc'
        let page = pagination?.pageIndex
        let batch_size = pagination?.pageSize
        let sortKey = sort?.active

        if (sortKey != null && sortDirection == 'desc')
            sortKey = '-' + sortKey;
        let params = {
            page: page ? ++page : 1,
            ordering: sortKey ?? '',
            search: query ? query : '',
            batch_size: batch_size ? batch_size : 10
        }
        if (user != null && user > 0)
            params['user'] = user
        if (startDate != null)
            params['start_date'] = startDate
        if (endDate != null)
            params['end_date'] = endDate
        if (ad != null && ad > 0)
            params['ad'] = ad

        return this._httpClient.get<FacebookLocationReportResponse>(`${BASE_URL}digital_marketing/facebook-location-reports/`, {
            params: params
        }).pipe(
            tap((response) => {
                if (!processResponse)
                    return
                this.errorMessage = response.message;
                this.locationReportPagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._locationReports.next(response.data ? response.data : []);
            })
        );
    }
    updateLocationReport(performanceData, itemId): Observable<any> {
        return this._httpClient.put(`${BASE_URL}digital_marketing/facebook-location-reports/${itemId}/`, performanceData);
    }
    createLocationReport(performanceData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/facebook-location-reports/`, performanceData);
    }
    deleteLocationReport(itemId): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}digital_marketing/facebook-location-reports/${itemId}/`).pipe(
            tap((response) => {
                this._locationReports.next(this._locationReports.value.filter(ad => ad.id !== itemId));
            })
        )
    }
    createBulkLocationReport(performanceData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/facebook-location-reports-update/`, performanceData);
    }
    // Location report - End
}
