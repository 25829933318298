import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, finalize, take, takeUntil } from 'rxjs/operators';
import { Output, EventEmitter } from '@angular/core';
@Component({
    selector: 'mat-select-search',
    template: '<input matInput placeholder="search" class="p-4" style="max-width: 100%;position: sticky;top: 0;background-color: #fff;z-index: 999999;border-bottom-width: 1px;border-bottom-style: solid;border-bottom-color: grey;" [formControl] = "searchFilterCtrl" > ',
})
export class MatSelectSearchComponent implements OnInit {
    @Output() onSearch = new EventEmitter<string>();
    public searchFilterCtrl: FormControl = new FormControl();
    ngOnInit(): void {
        this.searchFilterCtrl.valueChanges
            .pipe(
                debounceTime(400),
                distinctUntilChanged()
            )
            .subscribe(res => {
                this.onSearch.emit(res);
            });
    }
}