import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, tap, finalize } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { DMAdGroup, Advertiser, AdvertiserResponse, DMAdGroupResponse, DMCampaign, DMCampaignResponse, DMCreativeResponse, DMCreative, DMAdDevicesResponse, DMAdDevice, DMPlacement, DMPlacementResponse, DMGeography, DMGeographyResponse, DMDataElementResponse, DMDataElement } from './digital-marketing.model';
import { Pagination } from 'app/shared/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class DigitalMarketingService {

    private _dmCampaigns: BehaviorSubject<DMCampaign[] | null> = new BehaviorSubject(null);
    private _advertisers: BehaviorSubject<Advertiser[] | null> = new BehaviorSubject(null);
    private _dmAdGroups: BehaviorSubject<DMAdGroup[] | null> = new BehaviorSubject(null);
    private _dmCreatives: BehaviorSubject<DMCreative[] | null> = new BehaviorSubject(null);
    private _dmAdDevices: BehaviorSubject<DMAdDevice[] | null> = new BehaviorSubject(null);
    private _dmPlacements: BehaviorSubject<DMPlacement[] | null> = new BehaviorSubject(null);
    private _dmGeographies: BehaviorSubject<DMGeography[] | null> = new BehaviorSubject(null);
    private _dmDataElements: BehaviorSubject<DMDataElement[] | null> = new BehaviorSubject(null);

    private _dmCampaign: BehaviorSubject<DMCampaign | null> = new BehaviorSubject(null);
    private _dmAdGroup: BehaviorSubject<DMAdGroup | null> = new BehaviorSubject(null);
    private _dmCreative: BehaviorSubject<DMCreative | null> = new BehaviorSubject(null);
    private _dmAdDevice: BehaviorSubject<DMAdDevice | null> = new BehaviorSubject(null);
    private _dmPlacement: BehaviorSubject<DMPlacement | null> = new BehaviorSubject(null);
    private _dmGeography: BehaviorSubject<DMGeography | null> = new BehaviorSubject(null);
    private _dmDataElement: BehaviorSubject<DMDataElement | null> = new BehaviorSubject(null);

    campaignPagination: Pagination;
    creativePagination: Pagination;
    adGroupPagination: Pagination;
    adDevicePagination: Pagination;
    placementPagination: Pagination;
    geographyPagination: Pagination;
    dataElementPagination: Pagination;
    errorMessage: string = "Something went wrong ";

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for dmCampaigns
     */
    get dmCampaigns$(): Observable<DMCampaign[]> {
        return this._dmCampaigns.asObservable();
    }

    get advertisers$(): Observable<Advertiser[]> {
        return this._advertisers.asObservable();
    }

    get adGroups$(): Observable<DMAdGroup[]> {
        return this._dmAdGroups.asObservable();
    }

    get adDevices$(): Observable<DMAdDevice[]> {
        return this._dmAdDevices.asObservable();
    }

    get creatives$(): Observable<DMCreative[]> {
        return this._dmCreatives.asObservable();
    }

    get placements$(): Observable<DMPlacement[]> {
        return this._dmPlacements.asObservable();
    }

    get dataElements$(): Observable<DMDataElement[]> {
        return this._dmDataElements.asObservable();
    }

    get geographies$(): Observable<DMGeography[]> {
        return this._dmGeographies.asObservable();
    }

    ////////
    get dmCampaign$(): Observable<DMCampaign> {
        return this._dmCampaign.asObservable();
    }

    get adGroup$(): Observable<DMAdGroup> {
        return this._dmAdGroup.asObservable();
    }

    get adDevice$(): Observable<DMAdDevice> {
        return this._dmAdDevice.asObservable();
    }

    get creative$(): Observable<DMCreative> {
        return this._dmCreative.asObservable();
    }

    get placement$(): Observable<DMPlacement> {
        return this._dmPlacement.asObservable();
    }

    get dataElement$(): Observable<DMDataElement> {
        return this._dmDataElement.asObservable();
    }

    get geography$(): Observable<DMGeography> {
        return this._dmGeography.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    //Advertisers
    getAdvertisors(query: string, isFromPopup: boolean = false): Observable<AdvertiserResponse> {
        let params = {
            page: 1,
            search: query ? query : '',
            batch_size: 50
        }
        return this._httpClient.get<AdvertiserResponse>(`${BASE_URL}digital_marketing/advertisers/`, {
            params: params
        }).pipe(
            tap((response) => {
                if (isFromPopup)
                    return
                else
                    this._advertisers.next(response.data ? response.data : []);
            })
        );
    }

    reportSync(syncData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/report_data_sync/`, syncData);
    }
    
    //Campaigns
    getDMCampaignsInitial(search: string = null): Observable<DMCampaignResponse> {
        return this.getDMCampaigns(null, null, search, null, null, false);
    }
    getDMCampaigns(pagination, sort, query: string, advertiser, user, reports: boolean): Observable<DMCampaignResponse> {
        let sortDirection = sort?.direction ?? 'asc'
        let page = pagination?.pageIndex
        let batch_size = pagination?.pageSize

        if (sortDirection == 'desc')
            sort = '-' + sort;
        let params = {
            page: page ? ++page : 1,
            order_by: sort?.active ?? '',
            search: query ? query : '',
            reports: reports ? 1 : 0,
            batch_size: batch_size ? batch_size : 10
        }
        if (advertiser != null && advertiser > 0)
            params['advertiser'] = advertiser
        if (user != null && user > 0)
            params['user'] = user
        return this._httpClient.get<DMCampaignResponse>(`${BASE_URL}digital_marketing/campaigns/`, {
            params: params
        }).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this.campaignPagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._dmCampaigns.next(response.data ? response.data : []);
            })
        );
    }
    getDMCampaignDetails(id): Observable<DMCampaign> {
        return this._httpClient.get<DMCampaign>(`${BASE_URL}digital_marketing/campaigns/${id}/?reports=1&summary=1`).pipe(
            tap((response) => {
                this._dmCampaign.next(response);
            })
        );
    }

    //Ad Groups
    getDMAdGroupsInitial(campaign: string = null): Observable<DMAdGroupResponse> {
        return this.getDMAdGroups(null, null, null, campaign, null, false);
    }
    getDMAdGroups(pagination, sort, query: string, campaign, user, reports: boolean): Observable<DMAdGroupResponse> {
        let sortDirection = sort?.direction ?? 'asc'
        let page = pagination?.pageIndex
        let batch_size = pagination?.pageSize

        if (sortDirection == 'desc')
            sort = '-' + sort;
        let params = {
            page: page ? ++page : 1,
            order_by: sort?.active ?? '',
            search: query ? query : '',
            reports: reports ? 1 : 0,
            batch_size: batch_size ? batch_size : 10
        }
        if (campaign != null && campaign > 0)
            params['campaign'] = campaign
        if (user != null && user > 0)
            params['user'] = user
        return this._httpClient.get<DMCampaignResponse>(`${BASE_URL}digital_marketing/ad_groups/`, {
            params: params
        }).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this.adGroupPagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._dmAdGroups.next(response.data ? response.data : []);
            })
        );
    }
    getDMAdGroupDetails(id): Observable<DMAdGroup> {
        return this._httpClient.get<DMAdGroup>(`${BASE_URL}digital_marketing/ad_groups/${id}/?reports=1`).pipe(
            tap((response) => {
                this._dmAdGroup.next(response);
            })
        );
    }

    //Creatives
    getDMCreativesInitial(campaign: string = null): Observable<DMCreativeResponse> {
        return this.getDMCreatives(null, null, null, campaign, null, false);
    }
    getDMCreatives(pagination, sort, query: string, campaign, user, reports: boolean): Observable<DMCreativeResponse> {
        let sortDirection = sort?.direction ?? 'asc'
        let page = pagination?.pageIndex
        let batch_size = pagination?.pageSize

        if (sortDirection == 'desc')
            sort = '-' + sort;
        let params = {
            page: page ? ++page : 1,
            order_by: sort?.active ?? '',
            search: query ? query : '',
            reports: reports ? 1 : 0,
            batch_size: batch_size ? batch_size : 10
        }
        if (campaign != null && campaign > 0)
            params['campaign'] = campaign
        if (user != null && user > 0)
            params['user'] = user
        return this._httpClient.get<DMCampaignResponse>(`${BASE_URL}digital_marketing/creatives/`, {
            params: params
        }).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this.creativePagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._dmCreatives.next(response.data ? response.data : []);
            })
        );
    }
    getDMCreativeDetails(id): Observable<DMCreative> {
        return this._httpClient.get<DMCreative>(`${BASE_URL}digital_marketing/creatives/${id}/?reports=1`).pipe(
            tap((response) => {
                this._dmCreative.next(response);
            })
        );
    }

    //Ad devices
    getDMAdDevicesInitial(campaign: string = null): Observable<DMAdDevicesResponse> {
        return this.getDMAdDevices(null, null, null, campaign, null, false);
    }
    getDMAdDevices(pagination, sort, query: string, campaign, user, reports: boolean): Observable<DMAdDevicesResponse> {
        let sortDirection = sort?.direction ?? 'asc'
        let page = pagination?.pageIndex
        let batch_size = pagination?.pageSize

        if (sortDirection == 'desc')
            sort = '-' + sort;
        let params = {
            page: page ? ++page : 1,
            order_by: sort?.active ?? '',
            search: query ? query : '',
            reports: reports ? 1 : 0,
            batch_size: batch_size ? batch_size : 10
        }
        if (campaign != null && campaign > 0)
            params['campaign'] = campaign
        if (user != null && user > 0)
            params['user'] = user
        return this._httpClient.get<DMAdDevicesResponse>(`${BASE_URL}digital_marketing/devices/`, {
            params: params
        }).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this.adDevicePagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._dmAdDevices.next(response.data ? response.data : []);
            })
        );
    }
    getDMAdDeviceDetails(id): Observable<DMAdDevice> {
        return this._httpClient.get<DMAdDevice>(`${BASE_URL}digital_marketing/devices/${id}/?reports=1`).pipe(
            tap((response) => {
                this._dmAdDevice.next(response);
            })
        );
    }

    //Placements
    getDMPlacementsInitial(campaign: string = null): Observable<DMPlacementResponse> {
        return this.getDMPlacements(null, null, null, campaign, null, false);
    }
    getDMPlacements(pagination, sort, query: string, campaign, user, reports: boolean): Observable<DMPlacementResponse> {
        let sortDirection = sort?.direction ?? 'asc'
        let page = pagination?.pageIndex
        let batch_size = pagination?.pageSize

        if (sortDirection == 'desc')
            sort = '-' + sort;
        let params = {
            page: page ? ++page : 1,
            order_by: sort?.active ?? '',
            search: query ? query : '',
            reports: reports ? 1 : 0,
            batch_size: batch_size ? batch_size : 10
        }
        if (campaign != null && campaign > 0)
            params['campaign'] = campaign
        if (user != null && user > 0)
            params['user'] = user
        return this._httpClient.get<DMPlacementResponse>(`${BASE_URL}digital_marketing/placements/`, {
            params: params
        }).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this.placementPagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._dmPlacements.next(response.data ? response.data : []);
            })
        );
    }
    getDMPlacementDetails(id): Observable<DMPlacement> {
        return this._httpClient.get<DMPlacement>(`${BASE_URL}digital_marketing/placements/${id}/?reports=1`).pipe(
            tap((response) => {
                this._dmPlacement.next(response);
            })
        );
    }

    //Geographies
    getDMGeographiesInitial(campaign: string = null): Observable<DMGeographyResponse> {
        return this.getDMGeographies(null, null, null, campaign, null, false);
    }
    getDMGeographies(pagination, sort, query: string, campaign, user, reports: boolean): Observable<DMGeographyResponse> {
        let sortDirection = sort?.direction ?? 'asc'
        let page = pagination?.pageIndex
        let batch_size = pagination?.pageSize

        if (sortDirection == 'desc')
            sort = '-' + sort;
        let params = {
            page: page ? ++page : 1,
            order_by: sort?.active ?? '',
            search: query ? query : '',
            reports: reports ? 1 : 0,
            batch_size: batch_size ? batch_size : 10
        }
        if (campaign != null && campaign > 0)
            params['campaign'] = campaign
        if (user != null && user > 0)
            params['user'] = user
        return this._httpClient.get<DMGeographyResponse>(`${BASE_URL}digital_marketing/geographies/`, {
            params: params
        }).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this.geographyPagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._dmGeographies.next(response.data ? response.data : []);
            })
        );
    }
    getDMGeographyDetails(id): Observable<DMGeography> {
        return this._httpClient.get<DMGeography>(`${BASE_URL}digital_marketing/geographies/${id}/?reports=1`).pipe(
            tap((response) => {
                this._dmGeography.next(response);
            })
        );
    }

    //Data Elements
    getDMDataElementsInitial(campaign: string = null): Observable<DMDataElementResponse> {
        return this.getDMDataElements(null, null, null, campaign, null, false);
    }
    getDMDataElements(pagination, sort, query: string, campaign, user, reports: boolean): Observable<DMDataElementResponse> {
        let sortDirection = sort?.direction ?? 'asc'
        let page = pagination?.pageIndex
        let batch_size = pagination?.pageSize

        if (sortDirection == 'desc')
            sort = '-' + sort;
        let params = {
            page: page ? ++page : 1,
            order_by: sort?.active ?? '',
            search: query ? query : '',
            reports: reports ? 1 : 0,
            batch_size: batch_size ? batch_size : 10
        }
        if (campaign != null && campaign > 0)
            params['campaign'] = campaign
        if (user != null && user > 0)
            params['user'] = user
        return this._httpClient.get<DMDataElementResponse>(`${BASE_URL}digital_marketing/data_element/`, {
            params: params
        }).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this.dataElementPagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._dmDataElements.next(response.data ? response.data : []);
            })
        );
    }
    getDMDataElementDetails(id): Observable<DMDataElement> {
        return this._httpClient.get<DMDataElement>(`${BASE_URL}digital_marketing/data_element/${id}/?reports=1`).pipe(
            tap((response) => {
                this._dmDataElement.next(response);
            })
        );
    }

    deleteDMCampaign() {

    }

}
