import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import {  Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { GroupService } from 'app/core/group/group.service';
import { InitialData } from './group.types';

@Injectable({
    providedIn: 'root'
})
export class GroupDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _groupService: GroupService, private router: Router) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        // API call to get groups llst
        return this._groupService.getGroupsInitial()
            .pipe(
                map((groups) => ({
                    groups
                })
                ),
                catchError(err => {
                    this._groupService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                    return of(new DefaultResponse().groups);
                })
            );
    }

}

class DefaultResponse implements InitialData {
    groups: any = [];
}