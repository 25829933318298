import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InitialData } from './campaign.types';
import { ContactService } from 'app/core/contact/contact.service';
import { CampaignService } from 'app/core/campaign/campaign.service';
import { FacebookService } from 'app/core/digital-marketing/facebook.service';

@Injectable({
    providedIn: 'root'
})
export class FBCampaignDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _fbService: FacebookService, private router: Router, private user: ContactService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        let isAdmin = localStorage.getItem('account_type')?.includes('admin')
        let campaigns = this._fbService.getFBCampaignsInitial().pipe(
            map((fbCampaigns) => ({
                fbCampaigns
            })
            ),
            catchError(err => {
                this._fbService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                return of(new DefaultResponse().campaigns);
            })
        )

        let users = this.user.getContacts('user', null, 100, null, null, null, null)

        let sources = [campaigns]
        if (isAdmin)
            sources.push(users)
        return forkJoin(sources)
    }

}

class DefaultResponse implements InitialData {
    campaigns: any = [];
}