import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { ContactService } from 'app/core/contact/contact.service';
import { GoogleService } from 'app/core/digital-marketing/google.service';

@Injectable({
    providedIn: 'root'
})
export class GoogleLandingPageDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _googleService: GoogleService, private router: Router, private user: ContactService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        let isAdmin = localStorage.getItem('account_type')?.includes('admin')
        let reports = this._googleService.getLandingPageReportsInitial()
        let users = this.user.getContacts('user', null, 100, null, null, null, null)
        let campaigns = this._googleService.getGoogleCampaigns(null, null, null, null, true)

        let sources = [reports, campaigns]
        if (isAdmin)
            sources.push(users)
            
        return forkJoin(sources)
    }

}