import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, tap, finalize } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { GoogleCampaignResponse, GoogleCampaign, GoogleKeywordReportResponse, GoogleKeywordReport, GoogleLocationReport, GoogleLocationReportResponse, GoogleAdScheduleReport, GoogleAdScheduleReportResponse, GoogleLandingPageReportResponse, GoogleLandingPageReport, GoogleDemographicReportResponse, GoogleDemographicReport, GoogleDeviceReport, GoogleDeviceReportResponse, GoogleCampaignPerformanceReport, GoogleCampaignPerformanceReportResponse } from './google.model';
import { Pagination } from 'app/shared/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class GoogleService {

    errorMessage: string = "Something went wrong ";

    constructor(private _httpClient: HttpClient) {
    }

    // Campaigns - Start
    private _googleCampaigns: BehaviorSubject<GoogleCampaign[] | null> = new BehaviorSubject(null);
    private _googleCampaign: BehaviorSubject<GoogleCampaign | null> = new BehaviorSubject(null);
    campaignPagination: Pagination;

    get googleCampaigns$(): Observable<GoogleCampaign[]> {
        return this._googleCampaigns.asObservable();
    }
    get googleCampaign$(): Observable<GoogleCampaign> {
        return this._googleCampaign.asObservable();
    }
    getGoogleCampaignsInitial(search: string = null): Observable<GoogleCampaignResponse> {
        return this.getGoogleCampaigns(null, null, search, null);
    }
    getGoogleCampaigns(pagination, sort, query: string, user, processResponse: boolean = true, campaignType: string = null): Observable<GoogleCampaignResponse> {
        let sortDirection = sort?.direction ?? 'asc'
        let page = pagination?.pageIndex
        let batch_size = pagination?.pageSize
        let sortKey = sort?.active

        if (sortKey != null && sortDirection == 'desc')
            sortKey = '-' + sortKey;
        let params = {
            page: page ? ++page : 1,
            ordering: sortKey ?? '',
            search: query ? query : '',
            batch_size: batch_size ? batch_size : 10
        }
        if (user != null && user > 0)
            params['user'] = user
        if (campaignType != null && campaignType != "0")
            params['type'] = campaignType
        return this._httpClient.get<GoogleCampaignResponse>(`${BASE_URL}digital_marketing/google-campaigns/`, {
            params: params
        }).pipe(
            tap((response) => {
                if (!processResponse)
                    return
                this.errorMessage = response.message;
                this.campaignPagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._googleCampaigns.next(response.data ? response.data : []);
            })
        );
    }
    updateGoogleCampaign(campaignData, campaignId): Observable<any> {
        return this._httpClient.put(`${BASE_URL}digital_marketing/google-campaigns/${campaignId}/`, campaignData);
    }
    createGoogleCampaign(campaignData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/google-campaigns/`, campaignData);
    }
    deleteGoogleCampaign(campaignId): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}digital_marketing/google-campaigns/${campaignId}/`).pipe(
            tap((response) => {
                this._googleCampaigns.next(this._googleCampaigns.value.filter(campaign => campaign.id !== campaignId));
            })
        )
    }

    updateBudgetRequest(campaignData, campaignId): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/google-campaign-update-request/`, campaignData);
    }
    // Campaigns - End

    // Keyword report - Start
    private _keywordReports: BehaviorSubject<GoogleKeywordReport[] | null> = new BehaviorSubject(null);
    private _keywordReport: BehaviorSubject<GoogleKeywordReport | null> = new BehaviorSubject(null);
    keywordReportPagination: Pagination;

    get keywordReports$(): Observable<GoogleKeywordReport[]> {
        return this._keywordReports.asObservable();
    }
    get keywordReport$(): Observable<GoogleKeywordReport> {
        return this._keywordReport.asObservable();
    }
    getKeywordReportsInitial(search: string = null): Observable<GoogleKeywordReportResponse> {
        return this.getKeywordReports(null, null, search, null, null, null, null, true);
    }
    getKeywordReports(pagination, sort, query: string, user, campaign, startDate, endDate, processResponse: boolean = true): Observable<GoogleKeywordReportResponse> {
        let sortDirection = sort?.direction ?? 'asc'
        let page = pagination?.pageIndex
        let batch_size = pagination?.pageSize
        let sortKey = sort?.active

        if (sortKey != null && sortDirection == 'desc')
            sortKey = '-' + sortKey;
        let params = {
            page: page ? ++page : 1,
            ordering: sortKey ?? '',
            search: query ? query : '',
            batch_size: batch_size ? batch_size : 10
        }
        if (user != null && user > 0)
            params['user'] = user
        if (startDate != null)
            params['start_date'] = startDate
        if (endDate != null)
            params['end_date'] = endDate
        if (campaign != null && campaign > 0)
            params['campaign'] = campaign

        return this._httpClient.get<GoogleKeywordReportResponse>(`${BASE_URL}digital_marketing/google-keyword-reports/`, {
            params: params
        }).pipe(
            tap((response) => {
                if (!processResponse)
                    return
                this.errorMessage = response.message;
                this.keywordReportPagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._keywordReports.next(response.data ? response.data : []);
            })
        );
    }
    updateKeywordReport(performanceData, itemId): Observable<any> {
        return this._httpClient.put(`${BASE_URL}digital_marketing/google-keyword-reports/${itemId}/`, performanceData);
    }
    createKeywordReport(performanceData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/google-keyword-reports/`, performanceData);
    }
    deleteKeywordReport(itemId): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}digital_marketing/google-keyword-reports/${itemId}/`).pipe(
            tap((response) => {
                this._keywordReports.next(this._keywordReports.value.filter(ad => ad.id !== itemId));
            })
        )
    }
    createBulkKeywordReport(data): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/google-keyword-reports-update/`, data);
    }
    // Keyword report - End

    // Demographic report - Start
    private _demographicReports: BehaviorSubject<GoogleDemographicReport[] | null> = new BehaviorSubject(null);
    private _demographicReport: BehaviorSubject<GoogleDemographicReport | null> = new BehaviorSubject(null);
    demographicReportPagination: Pagination;

    get demographicReports$(): Observable<GoogleDemographicReport[]> {
        return this._demographicReports.asObservable();
    }
    get demographicReport$(): Observable<GoogleDemographicReport> {
        return this._demographicReport.asObservable();
    }
    getDemographicReportsInitial(search: string = null): Observable<GoogleDemographicReportResponse> {
        return this.getDemographicReports(null, null, search, null, null, null, null, true);
    }
    getDemographicReports(pagination, sort, query: string, user, campaign, startDate, endDate, processResponse: boolean = true): Observable<GoogleDemographicReportResponse> {
        let sortDirection = sort?.direction ?? 'asc'
        let page = pagination?.pageIndex
        let batch_size = pagination?.pageSize
        let sortKey = sort?.active

        if (sortKey != null && sortDirection == 'desc')
            sortKey = '-' + sortKey;
        let params = {
            page: page ? ++page : 1,
            ordering: sortKey ?? '',
            search: query ? query : '',
            batch_size: batch_size ? batch_size : 10
        }
        if (user != null && user > 0)
            params['user'] = user
        if (startDate != null)
            params['start_date'] = startDate
        if (endDate != null)
            params['end_date'] = endDate
        if (campaign != null && campaign > 0)
            params['campaign'] = campaign

        return this._httpClient.get<GoogleDemographicReportResponse>(`${BASE_URL}digital_marketing/google-demographics-reports/`, {
            params: params
        }).pipe(
            tap((response) => {
                if (!processResponse)
                    return
                this.errorMessage = response.message;
                this.demographicReportPagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._demographicReports.next(response.data ? response.data : []);
            })
        );
    }
    updateDemographicReport(performanceData, itemId): Observable<any> {
        return this._httpClient.put(`${BASE_URL}digital_marketing/google-demographics-reports/${itemId}/`, performanceData);
    }
    createDemographicReport(performanceData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/google-demographics-reports/`, performanceData);
    }
    deleteDemographicReport(itemId): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}digital_marketing/google-demographics-reports/${itemId}/`).pipe(
            tap((response) => {
                this._demographicReports.next(this._demographicReports.value.filter(ad => ad.id !== itemId));
            })
        )
    }
    createBulkDemographicReport(data): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/google-demographics-reports-update/`, data);
    }
    // Demographic report - End

    // Location report - Start
    private _locationReports: BehaviorSubject<GoogleLocationReport[] | null> = new BehaviorSubject(null);
    private _locationReport: BehaviorSubject<GoogleLocationReport | null> = new BehaviorSubject(null);
    locationReportPagination: Pagination;

    get locationReports$(): Observable<GoogleLocationReport[]> {
        return this._locationReports.asObservable();
    }
    get locationReport$(): Observable<GoogleLocationReport> {
        return this._locationReport.asObservable();
    }
    getLocationReportsInitial(search: string = null): Observable<GoogleLocationReportResponse> {
        return this.getLocationReports(null, null, search, null, null, null, null, true);
    }
    getLocationReports(pagination, sort, query: string, user, campaign, startDate, endDate, processResponse: boolean = true): Observable<GoogleLocationReportResponse> {
        let sortDirection = sort?.direction ?? 'asc'
        let page = pagination?.pageIndex
        let batch_size = pagination?.pageSize
        let sortKey = sort?.active

        if (sortKey != null && sortDirection == 'desc')
            sortKey = '-' + sortKey;
        let params = {
            page: page ? ++page : 1,
            ordering: sortKey ?? '',
            search: query ? query : '',
            batch_size: batch_size ? batch_size : 10
        }
        if (user != null && user > 0)
            params['user'] = user
        if (startDate != null)
            params['start_date'] = startDate
        if (endDate != null)
            params['end_date'] = endDate
        if (campaign != null && campaign > 0)
            params['campaign'] = campaign

        return this._httpClient.get<GoogleLocationReportResponse>(`${BASE_URL}digital_marketing/google-location-reports/`, {
            params: params
        }).pipe(
            tap((response) => {
                if (!processResponse)
                    return
                this.errorMessage = response.message;
                this.locationReportPagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._locationReports.next(response.data ? response.data : []);
            })
        );
    }
    updateLocationReport(performanceData, itemId): Observable<any> {
        return this._httpClient.put(`${BASE_URL}digital_marketing/google-location-reports/${itemId}/`, performanceData);
    }
    createLocationReport(performanceData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/google-location-reports/`, performanceData);
    }
    deleteLocationReport(itemId): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}digital_marketing/google-location-reports/${itemId}/`).pipe(
            tap((response) => {
                this._locationReports.next(this._locationReports.value.filter(ad => ad.id !== itemId));
            })
        )
    }
    createBulkLocationReport(data): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/google-location-reports-update/`, data);
    }
    // Location report - End

    // AdSchedule report - Start
    private _adScheduleReports: BehaviorSubject<GoogleAdScheduleReport[] | null> = new BehaviorSubject(null);
    private _adScheduleReport: BehaviorSubject<GoogleAdScheduleReport | null> = new BehaviorSubject(null);
    adScheduleReportPagination: Pagination;

    get adScheduleReports$(): Observable<GoogleAdScheduleReport[]> {
        return this._adScheduleReports.asObservable();
    }
    get adScheduleReport$(): Observable<GoogleAdScheduleReport> {
        return this._adScheduleReport.asObservable();
    }
    getAdScheduleReportsInitial(search: string = null): Observable<GoogleAdScheduleReportResponse> {
        return this.getAdScheduleReports(null, null, search, null, null, null, null, true);
    }
    getAdScheduleReports(pagination, sort, query: string, user, campaign, startDate, endDate, processResponse: boolean = true): Observable<GoogleAdScheduleReportResponse> {
        let sortDirection = sort?.direction ?? 'asc'
        let page = pagination?.pageIndex
        let batch_size = pagination?.pageSize
        let sortKey = sort?.active

        if (sortKey != null && sortDirection == 'desc')
            sortKey = '-' + sortKey;
        let params = {
            page: page ? ++page : 1,
            ordering: sortKey ?? '',
            search: query ? query : '',
            batch_size: batch_size ? batch_size : 10
        }
        if (user != null && user > 0)
            params['user'] = user
        if (startDate != null)
            params['start_date'] = startDate
        if (endDate != null)
            params['end_date'] = endDate
        if (campaign != null && campaign > 0)
            params['campaign'] = campaign

        return this._httpClient.get<GoogleAdScheduleReportResponse>(`${BASE_URL}digital_marketing/google-ad-schedule-reports/`, {
            params: params
        }).pipe(
            tap((response) => {
                if (!processResponse)
                    return
                this.errorMessage = response.message;
                this.adScheduleReportPagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._adScheduleReports.next(response.data ? response.data : []);
            })
        );
    }
    updateAdScheduleReport(performanceData, itemId): Observable<any> {
        return this._httpClient.put(`${BASE_URL}digital_marketing/google-ad-schedule-reports/${itemId}/`, performanceData);
    }
    createAdScheduleReport(performanceData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/google-ad-schedule-reports/`, performanceData);
    }
    deleteAdScheduleReport(itemId): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}digital_marketing/google-ad-schedule-reports/${itemId}/`).pipe(
            tap((response) => {
                this._adScheduleReports.next(this._adScheduleReports.value.filter(ad => ad.id !== itemId));
            })
        )
    }
    createBulkAdScheduleReport(data): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/google-ad-schedule-reports-update/`, data);
    }
    // AdSchedule report - End

    // LandingPage report - Start
    private _landingPageReports: BehaviorSubject<GoogleLandingPageReport[] | null> = new BehaviorSubject(null);
    private _landingPageReport: BehaviorSubject<GoogleLandingPageReport | null> = new BehaviorSubject(null);
    landingPageReportPagination: Pagination;

    get landingPageReports$(): Observable<GoogleLandingPageReport[]> {
        return this._landingPageReports.asObservable();
    }
    get landingPageReport$(): Observable<GoogleLandingPageReport> {
        return this._landingPageReport.asObservable();
    }
    getLandingPageReportsInitial(search: string = null): Observable<GoogleLandingPageReportResponse> {
        return this.getLandingPageReports(null, null, search, null, null, null, null, true);
    }
    getLandingPageReports(pagination, sort, query: string, user, campaign, startDate, endDate, processResponse: boolean = true): Observable<GoogleLandingPageReportResponse> {
        let sortDirection = sort?.direction ?? 'asc'
        let page = pagination?.pageIndex
        let batch_size = pagination?.pageSize
        let sortKey = sort?.active

        if (sortKey != null && sortDirection == 'desc')
            sortKey = '-' + sortKey;
        let params = {
            page: page ? ++page : 1,
            ordering: sortKey ?? '',
            search: query ? query : '',
            batch_size: batch_size ? batch_size : 10
        }
        if (user != null && user > 0)
            params['user'] = user
        if (startDate != null)
            params['start_date'] = startDate
        if (endDate != null)
            params['end_date'] = endDate
        if (campaign != null && campaign > 0)
            params['campaign'] = campaign

        return this._httpClient.get<GoogleLandingPageReportResponse>(`${BASE_URL}digital_marketing/google-landing-page-reports/`, {
            params: params
        }).pipe(
            tap((response) => {
                if (!processResponse)
                    return
                this.errorMessage = response.message;
                this.landingPageReportPagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._landingPageReports.next(response.data ? response.data : []);
            })
        );
    }
    updateLandingPageReport(performanceData, itemId): Observable<any> {
        return this._httpClient.put(`${BASE_URL}digital_marketing/google-landing-page-reports/${itemId}/`, performanceData);
    }
    createLandingPageReport(performanceData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/google-landing-page-reports/`, performanceData);
    }
    deleteLandingPageReport(itemId): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}digital_marketing/google-landing-page-reports/${itemId}/`).pipe(
            tap((response) => {
                this._landingPageReports.next(this._landingPageReports.value.filter(ad => ad.id !== itemId));
            })
        )
    }
    createBulkLandingPageReport(data): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/google-landing-page-reports-update/`, data);
    }
    // LandingPage report - End

    // Campaign Performance report - Start
    private _campaignPerformanceReports: BehaviorSubject<GoogleCampaignPerformanceReport[] | null> = new BehaviorSubject(null);
    private _campaignPerformanceReport: BehaviorSubject<GoogleCampaignPerformanceReport | null> = new BehaviorSubject(null);
    campaignPerformanceReportPagination: Pagination;

    get campaignPerformanceReports$(): Observable<GoogleCampaignPerformanceReport[]> {
        return this._campaignPerformanceReports.asObservable();
    }
    get campaignPerformanceReport$(): Observable<GoogleCampaignPerformanceReport> {
        return this._campaignPerformanceReport.asObservable();
    }
    getCampaignPerformanceReportsInitial(search: string = null): Observable<GoogleCampaignPerformanceReportResponse> {
        return this.getCampaignPerformanceReports(null, null, search, null, null, null, null, true);
    }
    getCampaignPerformanceReports(pagination, sort, query: string, user, campaign, startDate, endDate, processResponse: boolean = true): Observable<GoogleCampaignPerformanceReportResponse> {
        let sortDirection = sort?.direction ?? 'asc'
        let page = pagination?.pageIndex
        let batch_size = pagination?.pageSize
        let sortKey = sort?.active

        if (sortKey != null && sortDirection == 'desc')
            sortKey = '-' + sortKey;
        let params = {
            page: page ? ++page : 1,
            ordering: sortKey ?? '',
            search: query ? query : '',
            batch_size: batch_size ? batch_size : 10
        }
        if (user != null && user > 0)
            params['user'] = user
        if (startDate != null)
            params['start_date'] = startDate
        if (endDate != null)
            params['end_date'] = endDate
        if (campaign != null && campaign > 0)
            params['campaign'] = campaign

        return this._httpClient.get<GoogleCampaignPerformanceReportResponse>(`${BASE_URL}digital_marketing/google-campaign-performance-reports/`, {
            params: params
        }).pipe(
            tap((response) => {
                if (!processResponse)
                    return
                this.errorMessage = response.message;
                this.campaignPerformanceReportPagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._campaignPerformanceReports.next(response.data ? response.data : []);
            })
        );
    }
    updateCampaignPerformanceReport(performanceData, itemId): Observable<any> {
        return this._httpClient.put(`${BASE_URL}digital_marketing/google-campaign-performance-reports/${itemId}/`, performanceData);
    }
    createCampaignPerformanceReport(performanceData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/google-campaign-performance-reports/`, performanceData);
    }
    deleteCampaignPerformanceReport(itemId): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}digital_marketing/google-campaign-performance-reports/${itemId}/`).pipe(
            tap((response) => {
                this._campaignPerformanceReports.next(this._campaignPerformanceReports.value.filter(ad => ad.id !== itemId));
            })
        )
    }
    createBulkCampaignPerformanceReport(data): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/google-campaign-performance-reports-update/`, data);
    }
    // Campaign Performance report - End

    // Device report - Start
    private _deviceReports: BehaviorSubject<GoogleDeviceReport[] | null> = new BehaviorSubject(null);
    private _deviceReport: BehaviorSubject<GoogleDeviceReport | null> = new BehaviorSubject(null);
    deviceReportPagination: Pagination;

    get deviceReports$(): Observable<GoogleDeviceReport[]> {
        return this._deviceReports.asObservable();
    }
    get deviceReport$(): Observable<GoogleDeviceReport> {
        return this._deviceReport.asObservable();
    }
    getDeviceReportsInitial(search: string = null): Observable<GoogleDeviceReportResponse> {
        return this.getDeviceReports(null, null, search, null, null, null, null, true);
    }
    getDeviceReports(pagination, sort, query: string, user, campaign, startDate, endDate, processResponse: boolean = true): Observable<GoogleDeviceReportResponse> {
        let sortDirection = sort?.direction ?? 'asc'
        let page = pagination?.pageIndex
        let batch_size = pagination?.pageSize
        let sortKey = sort?.active

        if (sortKey != null && sortDirection == 'desc')
            sortKey = '-' + sortKey;
        let params = {
            page: page ? ++page : 1,
            ordering: sortKey ?? '',
            search: query ? query : '',
            batch_size: batch_size ? batch_size : 10
        }
        if (user != null && user > 0)
            params['user'] = user
        if (startDate != null)
            params['start_date'] = startDate
        if (endDate != null)
            params['end_date'] = endDate
        if (campaign != null && campaign > 0)
            params['campaign'] = campaign

        return this._httpClient.get<GoogleDeviceReportResponse>(`${BASE_URL}digital_marketing/google-device-reports/`, {
            params: params
        }).pipe(
            tap((response) => {
                if (!processResponse)
                    return
                this.errorMessage = response.message;
                this.deviceReportPagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._deviceReports.next(response.data ? response.data : []);
            })
        );
    }
    updateDeviceReport(performanceData, itemId): Observable<any> {
        return this._httpClient.put(`${BASE_URL}digital_marketing/google-device-reports/${itemId}/`, performanceData);
    }
    createDeviceReport(performanceData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/google-device-reports/`, performanceData);
    }
    deleteDeviceReport(itemId): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}digital_marketing/google-device-reports/${itemId}/`).pipe(
            tap((response) => {
                this._deviceReports.next(this._deviceReports.value.filter(ad => ad.id !== itemId));
            })
        )
    }
    createBulkDeviceReport(data): Observable<any> {
        return this._httpClient.post(`${BASE_URL}digital_marketing/google-device-reports-update/`, data);
    }
    // Device report - End
}
