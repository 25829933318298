import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InitialData } from './campaign-detail.types';
import { ToastrService } from 'ngx-toastr';
import { DigitalMarketingService } from 'app/core/digital-marketing/digital-marketing.service';

@Injectable({
    providedIn: 'root'
})
export class DmCampaignDetailDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _dmService: DigitalMarketingService,
        private _router: Router, private _toaster: ToastrService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        return this._dmService.getDMCampaignDetails(route.params['id'])
            .pipe(
                map((campaign) => ({
                    campaign
                })
                ),
                catchError(err => {
                    this._toaster.error("Couldn't find the specified creative")
                    this._router.navigate(['/']);
                    return of(new DefaultResponse().campaign);
                })
            );
    }

}
class DefaultResponse implements InitialData {
    campaign: any = {};
}