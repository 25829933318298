import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, tap, finalize } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { Campaign, CampaignDetails, CampaignResponse } from './campaign.model';
import { Pagination } from 'app/shared/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class CampaignService {

    private _campaigns: BehaviorSubject<Campaign[] | null> = new BehaviorSubject(null);
    private _campaign: BehaviorSubject<Campaign | null> = new BehaviorSubject(null);
    pagination: Pagination;
    errorMessage: string = "Something went wrong ";

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for campaigns
     */
    get campaigns$(): Observable<Campaign[]> {
        return this._campaigns.asObservable();
    }

    get campaign$(): Observable<Campaign> {
        return this._campaign.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get campaign list
     */
    getCampaignsInitial(type: string = ''): Observable<CampaignResponse> {
        return this.getCampaigns(type, null, null, null, null, null);
    }

    /**
     * Get campaign list with search and sort
     */
    getCampaigns(type: string = '', page: number = 0, batch_size: number = 10, sort: string = 'added_at', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string): Observable<CampaignResponse> {
        if (sortDirection == 'desc')
            sort = '-' + sort;
        return this._httpClient.get<CampaignResponse>(`${BASE_URL}marketing/campaign/`, {
            params: {
                page: page ? ++page : 1,
                order_by: sort ? sort : '',
                search: query ? query : '',
                batch: batch_size ? batch_size : 10,
                status: type
            }
        }).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this.pagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._campaigns.next(response.data ? response.data : []);
            })
        );
    }

    /**
     * Activate or suspend an campaign
     * @param campaignData 'true' to activate 'false' to suspend
     * @param campaignId
     */
    updateCampaign(campaignData, campaignId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}marketing/campaign/${campaignId}/`, campaignData);
    }

    /**
    * Create campaign
    *
    * @param campaignData
    */
    createCampaign(campaignData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}marketing/campaign/`, campaignData);
    }

    /**
    * Delete campaign
    *
    * @param campaignId
    */
    deleteCampaign(campaignId): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}marketing/campaign/${campaignId}/`).pipe(
            tap((response) => {
                this._campaigns.next(this._campaigns.value.filter(campaign => campaign.id !== campaignId));
            })
        )
    }

    getCampaignDetails(id): Observable<CampaignDetails> {
        return this._httpClient.get<CampaignDetails>(`${BASE_URL}marketing/campaign/${id}/`).pipe(
            tap((response) => {
                this._campaign.next(response.data);
            })
        );
    }

    runCampaign(campaignId: number): Observable<any> {
        return this._httpClient.post(`${BASE_URL}marketing/campaign/${campaignId}/run/`,{});
    }

}
