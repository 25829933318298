import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { InitialData } from './ad-device-detail.types';
import { ToastrService } from 'ngx-toastr';
import { DigitalMarketingService } from 'app/core/digital-marketing/digital-marketing.service';

@Injectable({
    providedIn: 'root'
})
export class DmAdDeviceDetailDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _dmService: DigitalMarketingService,
        private _router: Router, private _toaster: ToastrService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        return this._dmService.getDMAdDeviceDetails(route.params['id'])
            .pipe(
                map((adDevice) => ({
                    adDevice
                })
                ),
                catchError(err => {
                    this._toaster.error("Couldn't find the specified ad group")
                    this._router.navigate(['/']);
                    return of(new DefaultResponse().adDevice);
                })
            );
    }

}
class DefaultResponse implements InitialData {
    adDevice: any = {};
}