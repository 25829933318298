import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ContactService } from 'app/core/contact/contact.service';
import { InitialData } from './contact.types';

@Injectable({
    providedIn: 'root'
})
export class ContactDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _contactService: ContactService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        return this._contactService.getContactsInitial(this.getUserType(state.url))
            .pipe(
                map((contacts) => ({
                    contacts
                })
                ),
                catchError(err => {
                    this._contactService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                    return of(new DefaultResponse().contacts);
                })
            );
    }

    private getUserType(url:string) : string {
        switch(url)
        {
            case '/users/all' : {
                return 'all';
            }
            case '/users/app-users' : {
                return 'user';
            }
            case '/users/contacts' : {
                return 'contact';
            }
            default :{
                return 'all';
            }
        }
    }

}
class DefaultResponse implements InitialData {
    contacts: any = [];
}