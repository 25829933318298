import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, tap, finalize } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { Group, GroupResponse } from './group.model';
import { Pagination } from 'app/shared/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class GroupService {

    private _groups: BehaviorSubject<Group[] | null> = new BehaviorSubject(null);
    pagination: Pagination;
    errorMessage: string = "Something went wrong ";

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for groups
     */
    get groups$(): Observable<Group[]> {
        return this._groups.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get group list
     */
    getGroupsInitial(): Observable<GroupResponse> {
        return this.getGroups(null, null, null, null, null);
    }

    /**
     * Get group list with search and sort
     */
    getGroups(page: number = 0, batch_size: number = 10, sort: string = 'added_at', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string, needPagination:boolean = true): Observable<GroupResponse> {
        if (sortDirection == 'desc')
            sort = '-' + sort;
        return this._httpClient.get<GroupResponse>(`${BASE_URL}marketing/group/`, {
            params: {
                page: page ? ++page : 1,
                order_by: sort ? sort : '',
                search: query ? query : '',
                batch: batch_size ? batch_size : 10,
                pagination: needPagination == false ? 0 : 1
            }
        }).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this.pagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._groups.next(response.data ? response.data : []);
            })
        );
    }

    /**
     * Activate or suspend an group
     * @param groupData 'true' to activate 'false' to suspend
     * @param groupId
     */
    updateGroup(groupData, groupId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}marketing/group/${groupId}/`, groupData);
    }

    /**
    * Create group
    *
    * @param groupData
    */
    createGroup(groupData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}marketing/group/`, groupData);
    }

    /**
    * Delete group
    *
    * @param groupId
    */
    deleteGroup(groupId): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}marketing/group/${groupId}/`).pipe(
            tap((response) => {
                this._groups.next(this._groups.value.filter(group => group.id !== groupId));
            })
        )
    }



}
