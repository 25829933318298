import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { ContactDataResolver } from './modules/admin/manage-contacts/contact.resolvers';
import { CampaignDataResolver } from './modules/admin/manage-campaigns/campaign.resolver';
import { IndustryDataResolver } from './modules/admin/manage-industry/industry.resolvers';
import { GroupDataResolver } from './modules/admin/manage-group/group.resolvers';
import { CampaignDetailDataResolver } from './modules/admin/manage-campaigns/campaign-detail/campaign-detail.resolver';
import { ContactDetailDataResolver } from './modules/admin/manage-contacts/contact-detail/contact-detail.resolver';
import { GroupContactDataResolver } from './modules/admin/manage-group-contacts/group-contact.resolvers';
import { SurveyDataResolver } from './modules/admin/manage-survey/survey.resolvers';
import { DMCampaignDataResolver } from './modules/admin/manage-dm-campaign/dm-campaign.resolvers';
import { DMAdGroupDataResolver } from './modules/admin/manage-dm-ad-group/dm-ad-group.resolvers';
import { DMCreativeDataResolver } from './modules/admin/manage-dm-creative/dm-creative.resolvers';
import { DMAdDeviceDataResolver } from './modules/admin/manage-dm-ad-device/dm-ad-device.resolvers';
import { DMAdPlacementDataResolver } from './modules/admin/manage-dm-ad-placement/dm-ad-placement.resolvers';
import { DMGeographiesDataResolver } from './modules/admin/manage-dm-ad-geography/dm-ad-geography.resolvers';
import { DMDataElementDataResolver } from './modules/admin/manage-dm-data-element/dm-data-element.resolvers';
import { DmAdGroupDetailDataResolver } from './modules/admin/manage-dm-ad-group/ad-group-detail/ad-group-detail.resolver';
import { DmCreativeDetailDataResolver } from './modules/admin/manage-dm-creative/creative-detail/creative-detail.resolver';
import { DmAdDeviceDetailDataResolver } from './modules/admin/manage-dm-ad-device/ad-device-detail/ad-device-detail.resolver';
import { DmPlacementDetailDataResolver } from './modules/admin/manage-dm-ad-placement/ad-placement-detail/ad-placement-detail.resolver';
import { DmGeographyDetailDataResolver } from './modules/admin/manage-dm-ad-geography/geography-detail/geography-detail.resolver';
import { DmDataElementDetailDataResolver } from './modules/admin/manage-dm-data-element/data-element-detail/data-element-detail.resolver';
import { DmCampaignDetailDataResolver } from './modules/admin/manage-dm-campaign/campaign-detail/campaign-detail.resolver';
import { FBCampaignDataResolver } from './modules/admin/marketing-report/facebook/campaign/campaign.resolvers';
import { FBAdDataResolver } from './modules/admin/marketing-report/facebook/ad/ad.resolvers';
import { GoogleCampaignDataResolver } from './modules/admin/marketing-report/google/campaign/campaign.resolvers';
import { FBCampaignPerformanceDataResolver } from './modules/admin/marketing-report/facebook/campaign-performance/campaign-performance.resolvers';
import { FBDemographicReportDataResolver } from './modules/admin/marketing-report/facebook/demographic-report/demographic-report.resolvers';
import { FBPlatformReportDataResolver } from './modules/admin/marketing-report/facebook/platform-report/platform-report.resolvers';
import { FBDeviceReportDataResolver } from './modules/admin/marketing-report/facebook/device-report/device-report.resolvers';
import { FBLocationReportDataResolver } from './modules/admin/marketing-report/facebook/location-report/location-report.resolvers';
import { GoogleKeywordDataResolver } from './modules/admin/marketing-report/google/keyword-report/keyword-report.resolvers';
import { GoogleDemographicDataResolver } from './modules/admin/marketing-report/google/demographic-report/demographic-report.resolvers';
import { GoogleLocationDataResolver } from './modules/admin/marketing-report/google/location-report/location-report.resolvers';
import { GoogleAdScheduleDataResolver } from './modules/admin/marketing-report/google/ad-schedule-report/ad-schedule-report.resolvers';
import { GoogleLandingPageDataResolver } from './modules/admin/marketing-report/google/landing-page-report/landing-page-report.resolvers';
import { GoogleCampaignPerformanceDataResolver } from './modules/admin/marketing-report/google/campaign-performance-report/campaign-performance-report.resolvers';
import { GoogleDeviceDataResolver } from './modules/admin/marketing-report/google/device-report/device-report.resolvers';


// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/digitalmarketing/campaign'
    { path: '', pathMatch: 'full', redirectTo: 'digitalmarketing/campaign' },

    // Redirect signed in user to the '/digitalmarketing/campaign'
    //
    // After the user signs in, the sign in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'digitalmarketing/campaign' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'confirmation-required', loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule) },
            { path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule) },
            { path: 'reset-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.module').then(m => m.AuthResetPasswordModule) },
            { path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule) },
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            { path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule) },
        ]
    },

    {
        path: 'test',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        loadChildren: () => import('app/shared/test/test.module').then(m => m.TestModule)
    },

    {
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        canActivate: [AuthGuard],
        path: 'mobile/digitalmarketing/facebook/ad', resolve: {
            data: FBAdDataResolver
        }, loadChildren: () => import('app/modules/admin/marketing-report/facebook/ad/ad.module').then(m => m.FBAdModule)

    },

    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: 'dashboard', loadChildren: () => import('app/modules/admin/dashboard/dashboard.module').then(m => m.ExampleModule) },
            { path: 'reedy-roof', loadChildren: () => import('app/modules/admin/reedy-roof/reedy-roof.module').then(m => m.ReedyRoofModule) },
            { path: 'profile', loadChildren: () => import('app/modules/admin/profile/profile.module').then(m => m.ProfileModule) },
            {
                path: 'users/all', resolve: {
                    admins: ContactDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-contacts/manage-contact.module').then(m => m.ManageContactModule)
            },
            {
                path: 'users/app-users', resolve: {
                    admins: ContactDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-contacts/manage-contact.module').then(m => m.ManageContactModule)
            },
            {
                path: 'users/contacts', resolve: {
                    admins: ContactDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-contacts/manage-contact.module').then(m => m.ManageContactModule)
            },
            {
                path: 'contact/:id', resolve: {
                    admins: ContactDetailDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-contacts/contact-detail/contact-detail.module').then(m => m.ContactDetailModule)
            },
            {
                path: 'campaign/all', resolve: {
                    admins: CampaignDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-campaigns/manage-campaign.module').then(m => m.ManageCampaignModule)
            },
            {
                path: 'campaign/completed', resolve: {
                    admins: CampaignDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-campaigns/manage-campaign.module').then(m => m.ManageCampaignModule)
            },
            {
                path: 'campaign/scheduled', resolve: {
                    admins: CampaignDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-campaigns/manage-campaign.module').then(m => m.ManageCampaignModule)
            },
            {
                path: 'campaign/:id', resolve: {
                    admins: CampaignDetailDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-campaigns/campaign-detail/campaign-detail.module').then(m => m.CampaignDetailModule)
            },
            {
                path: 'industry', resolve: {
                    admins: IndustryDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-industry/manage-industry.module').then(m => m.ManageIndustryModule)
            },
            {
                path: 'group', resolve: {
                    admins: GroupDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-group/manage-group.module').then(m => m.ManageGroupModule)
            },
            {
                path: 'group/:id', resolve: {
                    admins: GroupContactDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-group-contacts/manage-group-contact.module').then(m => m.ManageGroupContactModule)
            },
            {
                path: 'survey', resolve: {
                    admins: SurveyDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-survey/manage-survey.module').then(m => m.ManageSurveyModule)
            },
            {
                path: 'digitalmarketing/campaign', resolve: {
                    admins: DMCampaignDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-dm-campaign/manage-dm-campaign.module').then(m => m.ManageDMCampaignModule)
            },
            {
                path: 'digitalmarketing/campaign/:id', resolve: {
                    admins: DmCampaignDetailDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-dm-campaign/campaign-detail/campaign-detail.module').then(m => m.DMCampaignDetailModule)
            },
            {
                path: 'digitalmarketing/group', resolve: {
                    admins: DMAdGroupDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-dm-ad-group/manage-dm-ad-group.module').then(m => m.ManageDMAdGroupModule)
            },
            {
                path: 'digitalmarketing/group/:id', resolve: {
                    admins: DmAdGroupDetailDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-dm-ad-group/ad-group-detail/ad-group-detail.module').then(m => m.DMAdGroupDetailModule)
            },
            {
                path: 'digitalmarketing/creative', resolve: {
                    admins: DMCreativeDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-dm-creative/manage-dm-creative.module').then(m => m.ManageDMCreativeModule)
            },
            {
                path: 'digitalmarketing/creative/:id', resolve: {
                    admins: DmCreativeDetailDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-dm-creative/creative-detail/creative-detail.module').then(m => m.DMCreativeDetailModule)
            },
            {
                path: 'digitalmarketing/device', resolve: {
                    admins: DMAdDeviceDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-dm-ad-device/manage-dm-ad-device.module').then(m => m.ManageDMAdDeviceModule)
            },
            {
                path: 'digitalmarketing/device/:id', resolve: {
                    admins: DmAdDeviceDetailDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-dm-ad-device/ad-device-detail/ad-device-detail.module').then(m => m.DMAdDeviceDetailModule)
            },
            {
                path: 'digitalmarketing/placement', resolve: {
                    admins: DMAdPlacementDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-dm-ad-placement/manage-dm-ad-placement.module').then(m => m.ManageDMPlacementModule)
            },
            {
                path: 'digitalmarketing/placement/:id', resolve: {
                    admins: DmPlacementDetailDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-dm-ad-placement/ad-placement-detail/ad-placement-detail.module').then(m => m.DMPlacementDetailModule)
            },
            {
                path: 'digitalmarketing/geography', resolve: {
                    admins: DMGeographiesDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-dm-ad-geography/manage-dm-ad-geography.module').then(m => m.ManageDMGeographyModule)
            },
            {
                path: 'digitalmarketing/geography/:id', resolve: {
                    admins: DmGeographyDetailDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-dm-ad-geography/geography-detail/geography-detail.module').then(m => m.DMGeographyDetailModule)
            },
            {
                path: 'digitalmarketing/data_element', resolve: {
                    admins: DMDataElementDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-dm-data-element/manage-dm-data-element.module').then(m => m.ManageDMDataElementModule)
            },
            {
                path: 'digitalmarketing/data_element/:id', resolve: {
                    admins: DmDataElementDetailDataResolver
                }, loadChildren: () => import('app/modules/admin/manage-dm-data-element/data-element-detail/data-element-detail.module').then(m => m.DMDataElementDetailModule)
            },
            {
                path: 'digitalmarketing/facebook/campaign', resolve: {
                    data: FBCampaignDataResolver
                }, loadChildren: () => import('app/modules/admin/marketing-report/facebook/campaign/campaign.module').then(m => m.FBCampaignModule)
            },
            {
                path: 'digitalmarketing/facebook/ad', resolve: {
                    data: FBAdDataResolver
                }, loadChildren: () => import('app/modules/admin/marketing-report/facebook/ad/ad.module').then(m => m.FBAdModule)
            },
            {
                path: 'digitalmarketing/facebook/campaign-performance', resolve: {
                    data: FBCampaignPerformanceDataResolver
                }, loadChildren: () => import('app/modules/admin/marketing-report/facebook/campaign-performance/campaign-performance.module').then(m => m.FBCampaignPerformanceModule)
            },
            {
                path: 'digitalmarketing/facebook/demographic-report', resolve: {
                    data: FBDemographicReportDataResolver
                }, loadChildren: () => import('app/modules/admin/marketing-report/facebook/demographic-report/demographic-report.module').then(m => m.FBDemographicReportModule)
            },
            {
                path: 'digitalmarketing/facebook/platform-report', resolve: {
                    data: FBPlatformReportDataResolver
                }, loadChildren: () => import('app/modules/admin/marketing-report/facebook/platform-report/platform-report.module').then(m => m.FBPlatformReportModule)
            },
            {
                path: 'digitalmarketing/facebook/device-report', resolve: {
                    data: FBDeviceReportDataResolver
                }, loadChildren: () => import('app/modules/admin/marketing-report/facebook/device-report/device-report.module').then(m => m.FBDeviceReportModule)
            },
            {
                path: 'digitalmarketing/facebook/location-report', resolve: {
                    data: FBLocationReportDataResolver
                }, loadChildren: () => import('app/modules/admin/marketing-report/facebook/location-report/location-report.module').then(m => m.FBLocationReportModule)
            },


            {
                path: 'digitalmarketing/google/campaign', resolve: {
                    data: GoogleCampaignDataResolver
                }, loadChildren: () => import('app/modules/admin/marketing-report/google/campaign/campaign.module').then(m => m.GoogleCampaignModule)
            },
            {
                path: 'digitalmarketing/google/keyword-report', resolve: {
                    data: GoogleKeywordDataResolver
                }, loadChildren: () => import('app/modules/admin/marketing-report/google/keyword-report/keyword-report.module').then(m => m.GoogleKeywordReportModule)
            },
            {
                path: 'digitalmarketing/google/demographic-report', resolve: {
                    data: GoogleDemographicDataResolver
                }, loadChildren: () => import('app/modules/admin/marketing-report/google/demographic-report/demographic-report.module').then(m => m.GoogleDemographicReportModule)
            },
            {
                path: 'digitalmarketing/google/location-report', resolve: {
                    data: GoogleLocationDataResolver
                }, loadChildren: () => import('app/modules/admin/marketing-report/google/location-report/location-report.module').then(m => m.GoogleLocationReportModule)
            },
            {
                path: 'digitalmarketing/google/ad-schedule-report', resolve: {
                    data: GoogleAdScheduleDataResolver
                }, loadChildren: () => import('app/modules/admin/marketing-report/google/ad-schedule-report/ad-schedule-report.module').then(m => m.GoogleAdScheduleReportModule)
            },
            {
                path: 'digitalmarketing/google/landing-page-report', resolve: {
                    data: GoogleLandingPageDataResolver
                }, loadChildren: () => import('app/modules/admin/marketing-report/google/landing-page-report/landing-page-report.module').then(m => m.GoogleLandingPageReportModule)
            },
            {
                path: 'digitalmarketing/google/device-report', resolve: {
                    data: GoogleDeviceDataResolver
                }, loadChildren: () => import('app/modules/admin/marketing-report/google/device-report/device-report.module').then(m => m.GoogleDeviceReportModule)
            },
            {
                path: 'digitalmarketing/google/campaign-performance-report', resolve: {
                    data: GoogleCampaignPerformanceDataResolver
                }, loadChildren: () => import('app/modules/admin/marketing-report/google/campaign-performance-report/campaign-performance-report.module').then(m => m.GoogleDeviceReportModule)
            },
            { path: 'support', loadChildren: () => import('app/modules/admin/chat/chat.module').then(m => m.ChatModule) },
        ]
    },

    { path: "**", redirectTo: 'digitalmarketing/campaign' },
];
