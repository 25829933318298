import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import {  Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IndustryService } from 'app/core/industry/industry.service';
import { InitialData } from './industry.types';

@Injectable({
    providedIn: 'root'
})
export class IndustryDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _industryService: IndustryService, private router: Router) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        // API call to get industries llst
        return this._industryService.getIndustriesInitial()
            .pipe(
                map((industries) => ({
                    industries
                })
                ),
                catchError(err => {
                    this._industryService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                    return of(new DefaultResponse().industries);
                })
            );
    }

}

class DefaultResponse implements InitialData {
    industries: any = [];
}