import { FuseNavigationItem } from "@fuse/components/navigation";
import { ReplaySubject } from "rxjs";

export class PWANavigation {

    private _navigation: ReplaySubject<FuseNavigationItem[]> = new ReplaySubject<FuseNavigationItem[]>(1);
    navigations: FuseNavigationItem[] = [

        // {
        //     id: 'dashboard',
        //     title: 'Dashboard',
        //     type: 'basic',
        //     icon: 'heroicons_outline:home',
        //     link: '/dashboard'
        // },
        {
            id: 'configuration',
            title: 'Configuration',
            type: 'collapsable',
            icon: 'heroicons_outline:menu',
            children: [
                {
                    id: 'industry',
                    title: 'People Industries',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-sm-right',
                    link: '/industry'
                },
                {
                    id: 'group',
                    title: 'People Groups',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-sm-right',
                    link: '/group'
                }
            ],
        },
        {
            id: 'users',
            title: 'People',
            type: 'collapsable',
            icon: 'heroicons_outline:users',
            children: [
                {
                    id: 'users.all',
                    title: 'All',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-sm-right',
                    link: '/users/all'
                },
                {
                    id: 'users.appusers',
                    title: 'App Users',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-sm-right',
                    link: '/users/app-users'
                },
                {
                    id: 'users.contacts',
                    title: 'Contacts',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-sm-right',
                    link: '/users/contacts'
                }
            ],
        },
        {
            id: 'campaign',
            title: 'Campaign',
            type: 'collapsable',
            icon: 'heroicons_outline:speakerphone',
            children: [
                {
                    id: 'campaign.all',
                    title: 'All',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-sm-right',
                    link: '/campaign/all'
                },
                {
                    id: 'campaign.scheduled',
                    title: 'Scheduled',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-sm-right',
                    link: '/campaign/scheduled'
                },
                {
                    id: 'campaign.completed',
                    title: 'Completed',
                    type: 'basic',
                    icon: 'heroicons_outline:arrow-sm-right',
                    link: '/campaign/completed'
                }
            ],
        },
        {
            id: 'digital_marketing',
            title: 'Digital Marketing',
            type: 'collapsable',
            icon: 'heroicons_outline:status-online',
            children: [
                {
                    id: 'dm.retargeting',
                    title: 'Retargeting',
                    type: 'collapsable',
                    icon: 'ads_click',
                    children: [
                        {
                            id: 'dm.campaign',
                            title: 'Campaigns',
                            type: 'basic',
                            icon: 'heroicons_outline:arrow-sm-right',
                            link: '/digitalmarketing/campaign'
                        },
                        {
                            id: 'dm.group',
                            title: 'Ad Groups',
                            type: 'basic',
                            icon: 'heroicons_outline:arrow-sm-right',
                            link: '/digitalmarketing/group'
                        },
                        {
                            id: 'dm.creative',
                            title: 'Creatives',
                            type: 'basic',
                            icon: 'heroicons_outline:arrow-sm-right',
                            link: '/digitalmarketing/creative'
                        },
                        {
                            id: 'dm.device',
                            title: 'Ad Devices',
                            type: 'basic',
                            icon: 'heroicons_outline:arrow-sm-right',
                            link: '/digitalmarketing/device'
                        },
                        {
                            id: 'dm.placement',
                            title: 'Ad Placements',
                            type: 'basic',
                            icon: 'heroicons_outline:arrow-sm-right',
                            link: '/digitalmarketing/placement'
                        },
                        {
                            id: 'dm.geography',
                            title: 'Geographies',
                            type: 'basic',
                            icon: 'heroicons_outline:arrow-sm-right',
                            link: '/digitalmarketing/geography'
                        },
                        {
                            id: 'dm.data_element',
                            title: 'Data Elements',
                            type: 'basic',
                            icon: 'heroicons_outline:arrow-sm-right',
                            link: '/digitalmarketing/data_element'
                        }
                    ]
                },
                {
                    id: 'dm.facebook',
                    title: 'Facebook',
                    type: 'collapsable',
                    icon: 'pwa:facebook',
                    children: [
                        {
                            id: 'dm.facebook.campaign',
                            title: 'Campaigns',
                            type: 'basic',
                            icon: 'heroicons_outline:arrow-sm-right',
                            link: '/digitalmarketing/facebook/campaign'
                        },
                        {
                            id: 'dm.facebook.ad',
                            title: 'Ads',
                            type: 'basic',
                            icon: 'heroicons_outline:arrow-sm-right',
                            link: '/digitalmarketing/facebook/ad'
                        },
                        {
                            id: 'dm.facebook.campaign-performance',
                            title: 'Campaign Performance',
                            type: 'basic',
                            icon: 'heroicons_outline:arrow-sm-right',
                            link: '/digitalmarketing/facebook/campaign-performance'
                        },
                        {
                            id: 'dm.facebook.demographic-report',
                            title: 'Demographic Report',
                            type: 'basic',
                            icon: 'heroicons_outline:arrow-sm-right',
                            link: '/digitalmarketing/facebook/demographic-report'
                        },
                        {
                            id: 'dm.facebook.platform-report',
                            title: 'Platform Report',
                            type: 'basic',
                            icon: 'heroicons_outline:arrow-sm-right',
                            link: '/digitalmarketing/facebook/platform-report'
                        },
                        {
                            id: 'dm.facebook.device-report',
                            title: 'Device Report',
                            type: 'basic',
                            icon: 'heroicons_outline:arrow-sm-right',
                            link: '/digitalmarketing/facebook/device-report'
                        },
                        {
                            id: 'dm.facebook.location-report',
                            title: 'Location Report',
                            type: 'basic',
                            icon: 'heroicons_outline:arrow-sm-right',
                            link: '/digitalmarketing/facebook/location-report'
                        },
                    ]
                },
                {
                    id: 'dm.google',
                    title: 'Google',
                    type: 'collapsable',
                    icon: 'pwa:google',
                    children: [
                        {
                            id: 'dm.google.campaign',
                            title: 'Campaigns',
                            type: 'basic',
                            icon: 'heroicons_outline:arrow-sm-right',
                            link: '/digitalmarketing/google/campaign'
                        },
                        {
                            id: 'dm.google.campaign-performance',
                            title: 'Campaign Performance Report',
                            type: 'basic',
                            icon: 'heroicons_outline:arrow-sm-right',
                            link: '/digitalmarketing/google/campaign-performance-report'
                        },
                        {
                            id: 'dm.google.keyword-report',
                            title: 'Keyword Report',
                            type: 'basic',
                            icon: 'heroicons_outline:arrow-sm-right',
                            link: '/digitalmarketing/google/keyword-report'
                        },
                        {
                            id: 'dm.google.demographic-report',
                            title: 'Demographic Report',
                            type: 'basic',
                            icon: 'heroicons_outline:arrow-sm-right',
                            link: '/digitalmarketing/google/demographic-report'
                        },
                        {
                            id: 'dm.google.location-report',
                            title: 'Location Report',
                            type: 'basic',
                            icon: 'heroicons_outline:arrow-sm-right',
                            link: '/digitalmarketing/google/location-report'
                        },
                        {
                            id: 'dm.google.ad-schedule-report',
                            title: 'Ad Schedule Report',
                            type: 'basic',
                            icon: 'heroicons_outline:arrow-sm-right',
                            link: '/digitalmarketing/google/ad-schedule-report'
                        },
                        {
                            id: 'dm.google.device',
                            title: 'Device Report',
                            type: 'basic',
                            icon: 'heroicons_outline:arrow-sm-right',
                            link: '/digitalmarketing/google/device-report'
                        },
                        {
                            id: 'dm.google.landing-page-report',
                            title: 'Landing Page Report',
                            type: 'basic',
                            icon: 'heroicons_outline:arrow-sm-right',
                            link: '/digitalmarketing/google/landing-page-report'
                        },
                    ]
                },
            ]
        },
        {
            id: 'chat',
            title: 'Customer Support',
            type: 'basic',
            icon: 'mat_outline:support_agent',
            link: '/support'
        },
    ];

    navigation: FuseNavigationItem[]

    userNavigation = ['']

    constructor() {
        let navigationArrays = this.getNavigationArrayForUser()
        this.navigation = this.navigations.filter(nav => {
            return navigationArrays.includes(nav.id)
        })
    }

    private getNavigationArrayForUser() {
        let userType = localStorage.getItem('account_type')
        // navigationItems = ['configuration', 'users', 'campaign', 'digital_marketing', 'chat']
        // 'user', 'super_admin', 'admin', 'marketing_admin', 'customer_support', 'pwa_designer'
        switch (userType) {
            case 'user': return ['digital_marketing']
            case 'super_admin': return ['configuration', 'users', 'campaign', 'digital_marketing', 'chat']
            case 'admin': return ['configuration', 'users', 'campaign', 'digital_marketing', 'chat']
            case 'marketing_admin': return ['configuration', 'users', 'campaign', 'digital_marketing', 'chat']
            case 'customer_support': return ['chat']
            default: return ['digital_marketing']
        }
    }
}