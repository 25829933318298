import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DigitalMarketingService } from 'app/core/digital-marketing/digital-marketing.service';
import { InitialData } from './dm-campaign.types';
import { ContactService } from 'app/core/contact/contact.service';

@Injectable({
    providedIn: 'root'
})
export class DMCampaignDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _dmCampaignService: DigitalMarketingService, private router: Router, private user: ContactService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        let campaigns = this._dmCampaignService.getDMCampaignsInitial().pipe(
            map((dmCampaigns) => ({
                dmCampaigns
            })
            ),
            catchError(err => {
                this._dmCampaignService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                return of(new DefaultResponse().dmCampaigns);
            })
        )
        let advertisers = this._dmCampaignService.getAdvertisors(null)
        let users = this.user.getContacts('user', null, null, null, null, null, null)

        let sources = [campaigns]

        if (localStorage.getItem('account_type')?.includes('admin')) {
            sources.push(advertisers)
            sources.push(users)
        }
        return forkJoin(sources)
    }

}

class DefaultResponse implements InitialData {
    dmCampaigns: any = [];
}