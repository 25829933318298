import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ContactService } from 'app/core/contact/contact.service';
import { FacebookService } from 'app/core/digital-marketing/facebook.service';

@Injectable({
    providedIn: 'root'
})
export class FBDemographicReportDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _fbService: FacebookService, private router: Router, private user: ContactService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        let isAdmin = localStorage.getItem('account_type')?.includes('admin')
        let reports = this._fbService.getDemographicReportsInitial()
        let users = this.user.getContacts('user', null, 100, null, null, null, null)
        let ads = this._fbService.getFBAds(null, null, null, null, null, true)

        let sources = [reports, ads]
        if (isAdmin)
            sources.push(users)
            
        return forkJoin(sources)
    }

}