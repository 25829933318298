import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, tap, finalize } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { Contact, ContactResponse, Order, OrderListResponse } from './contact.model';
import { Pagination } from 'app/shared/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class ContactService {

    private _contacts: BehaviorSubject<Contact[] | null> = new BehaviorSubject(null);
    private _orders: BehaviorSubject<Order[] | null> = new BehaviorSubject(null);
    private _contact: BehaviorSubject<Contact | null> = new BehaviorSubject(null);
    pagination: Pagination;
    errorMessage: string = "Something went wrong ";

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for contacts
     */
    get contacts$(): Observable<Contact[]> {
        return this._contacts.asObservable();
    }

    get contact$(): Observable<Contact> {
        return this._contact.asObservable();
    }

    get orders$(): Observable<Order[]> {
        return this._orders.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get contact list
     */
    getContactsInitial(userType: string = 'all'): Observable<ContactResponse> {
        return this.getContacts(userType, null, null, null, null, null);
    }

    /**
     * Get contact list with search and sort
     */
    getContacts(contactType: string, page: number = 0, batch_size: number = 10, sort: string = 'name', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string, groupId: number = null, isFromPopup: boolean = false): Observable<ContactResponse> {
        if (sortDirection == 'desc')
            sort = '-' + sort;

        let params = {
            page: page ? ++page : 1,
            order_by: sort ? sort : '',
            search: query ? query : '',
            batch: batch_size ? batch_size : 10,
            user_type: contactType ? contactType : 'all'
        }
        if (groupId != null)
            params['group'] = groupId
        return this._httpClient.get<ContactResponse>(`${BASE_URL}marketing/contacts/`, {
            params: params
        }).pipe(
            tap((response) => {
                if (isFromPopup) {
                    return
                }
                this.errorMessage = response.message;
                this.pagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._contacts.next(response.data ? response.data : []);
            })
        );
    }

    /**
     * Activate or suspend an contact
     * @param contactData 'true' to activate 'false' to suspend
     * @param contactId
     */
    updateContact(contactData, contactId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}marketing/contact/${contactId}/`, contactData);
    }

    /**
    * Create contact
    *
    * @param contactData
    */
    createContact(contactData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}marketing/contact/`, contactData);
    }

    /**
    * Delete contact
    *
    * @param contactId
    */
    deleteContact(contactId): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}marketing/contact/${contactId}/`).pipe(
            tap((response) => {
                this._contacts.next(this._contacts.value.filter(contact => contact.id !== contactId));
            })
        )
    }

    getContactDetails(id): Observable<Contact> {
        return this._httpClient.get<Contact>(`${BASE_URL}marketing/contact/${id}/`).pipe(
            tap((response) => {
                this._contact.next(response);
            })
        );
    }

    uploadCSV(formData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}marketing/contacts/upload/`, formData);
    }

    downloadSampleCSV() {
        const httpOptions = {
            responseType: 'blob' as 'json'
        };
        return this._httpClient.get<any>(`${BASE_URL}marketing/contacts/upload/`, httpOptions);
    }

    getContactOrders(productId: number, page: number = 0, batch_size: number = 10, search: string): Observable<OrderListResponse> {
        return this._httpClient.get<OrderListResponse>(`${BASE_URL}marketing/contact/${productId}/orders/`, {
            params: {
                order_search: search,
                page: page ? ++page : 1,
                batch: batch_size ? batch_size : 10
            }
        }).pipe(
            tap((response: OrderListResponse) => {
                this.errorMessage = response.message;
                this.pagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._orders.next(response.data ? response.data : []);
            })
        );
    }

}
