import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CampaignService } from 'app/core/campaign/campaign.service';
import { InitialData } from './campaign.types';

@Injectable({
    providedIn: 'root'
})
export class CampaignDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _campaignService: CampaignService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        return this._campaignService.getCampaignsInitial(this.getCampaignType(state.url))
            .pipe(
                map((campaigns) => ({
                    campaigns
                })
                ),
                catchError(err => {
                    this._campaignService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                    return of(new DefaultResponse().campaigns);
                })
            );
    }
    
    private getCampaignType(url:string) : string {
        switch(url)
        {
            case '/campaign/all' : {
                return '';
            }
            case '/campaign/scheduled' : {
                return 'scheduled';
            }
            case '/campaign/completed' : {
                return 'completed';
            }
            default :{
                return '';
            }
        }
    }

}
class DefaultResponse implements InitialData {
    campaigns: any = [];
}