import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject, BehaviorSubject } from 'rxjs';
import { map, catchError, switchMap, tap, finalize } from 'rxjs/operators';
import { BASE_URL } from 'environments/environment';
import { Industry, IndustryResponse } from './industry.model';
import { Pagination } from 'app/shared/pagination.type';

@Injectable({
    providedIn: 'root'
})
export class IndustryService {

    private _industries: BehaviorSubject<Industry[] | null> = new BehaviorSubject(null);
    pagination: Pagination;
    errorMessage: string = "Something went wrong ";

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {
    }

    /**
     * Getter for industrys
     */
    get industries$(): Observable<Industry[]> {
        return this._industries.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get industry list
     */
    getIndustriesInitial(): Observable<IndustryResponse> {
        return this.getIndustries(null, null, null, null, null);
    }

    /**
     * Get industry list with search and sort
     */
    getIndustries(page: number = 0, batch_size: number = 10, sort: string = 'added_at', sortDirection: 'asc' | 'desc' | '' = 'asc', query: string, pagination :boolean = true): Observable<IndustryResponse> {
        if (sortDirection == 'desc')
            sort = '-' + sort;
        return this._httpClient.get<IndustryResponse>(`${BASE_URL}marketing/industry/`, {
            params: {
                page: page ? ++page : 1,
                order_by: sort ? sort : '',
                search: query ? query : '',
                batch: batch_size ? batch_size : 10,
                pagination : pagination == false ? 0 : 1
            }
        }).pipe(
            tap((response) => {
                this.errorMessage = response.message;
                this.pagination = {
                    current_page: --response.current_page,
                    total_count: response.total_count
                };
                this._industries.next(response.data ? response.data : []);
            })
        );
    }

    /**
     * Activate or suspend an industry
     * @param industryData 'true' to activate 'false' to suspend
     * @param industryId
     */
    updateIndustry(industryData, industryId: number): Observable<any> {
        return this._httpClient.put(`${BASE_URL}marketing/industry/${industryId}/`, industryData);
    }

    /**
    * Create industry
    *
    * @param industryData
    */
    createIndustry(industryData): Observable<any> {
        return this._httpClient.post(`${BASE_URL}marketing/industry/`, industryData);
    }

    /**
    * Delete industry
    *
    * @param industryId
    */
    deleteIndustry(industryId): Observable<any> {
        return this._httpClient.delete(`${BASE_URL}marketing/industry/${industryId}/`).pipe(
            tap((response) => {
                this._industries.next(this._industries.value.filter(industry => industry.id !== industryId));
            })
        )
    }



}
