import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, tap, of } from 'rxjs';
import { User } from 'app/core/user/user.types';
import { BASE_URL } from 'environments/environment';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private router: Router) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {       
        // Store the value
        localStorage.setItem('account_type', value.account_type)    
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data
     */
    get(): Observable<User> {
        return this._httpClient.get<User>(`${BASE_URL}user/profile/`).pipe(
            tap((user) => {
                this._user.next(user);     
                localStorage.setItem('account_type', user.account_type)
            })
        );
    }

    /**
     * Update self profile
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient.post<User>(`${BASE_URL}user/profile/`, {
            first_name: user.first_name,
            middle_name: user.middle_name,
            last_name: user.last_name,
            phone_number: user.phone_number
        }).pipe(
            map((response) => {
                // Execute the observable
                this.user = response;
                return of(response);
            })
        );
    }

    /**
     * Update password
     *
     * @param user
     */
    updatePassword(user): Observable<any> {
        return this._httpClient.post<User>(`${BASE_URL}user/profile/`, { password: user.password, old_password: user.old_password });
    }

    /**
     * Update Profile pic
     *
     * @param formData with file "profile_image"
     */
    public updateProfilePicture(formData): Observable<any> {
        return this._httpClient.post<any>(`${BASE_URL}user/profile/`, formData).pipe(
            map((response) => {
                // Execute the observable
                this.user = response;
                return of(response);
            })
        );
    }
}
