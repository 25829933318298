import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CampaignService } from 'app/core/campaign/campaign.service';
import { InitialData } from './campaign-detail.types';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class CampaignDetailDataResolver implements Resolve<any>
{
    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _campaignService: CampaignService, 
        private _router:Router, private _toaster:ToastrService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<InitialData> {
        return this._campaignService.getCampaignDetails(route.params['id'])
            .pipe(
                map((campaign) => ({
                    campaign
                })
                ),
                catchError(err => {
                    this._toaster.error("Couldn't find the specified campaign")
                    this._router.navigate(['/']);
                    this._campaignService.errorMessage = err.error.message ? err.error.message : "Something went wrong !";
                    return of(new DefaultResponse().campaign);
                })
            );
    }

}
class DefaultResponse implements InitialData {
    campaign: any = {};
}